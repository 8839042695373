var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      staticClass: "h-75 d-inline-block",
      attrs: {
        id: _vm.id,
        size: "lg",
        scrollable: "",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", { attrs: { icon: "house" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Crea indirizzo")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mx-1" },
        [
          _c(
            "b-overlay",
            {
              attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
              scopedSlots: _vm._u([
                {
                  key: "overlay",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("base-icon", {
                            attrs: {
                              name: "loading",
                              width: "35",
                              height: "35",
                            },
                          }),
                          _vm._v(" "),
                          _c("p", { attrs: { id: "cancel-label" } }, [
                            _vm._v("Operazione in corso..."),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              !_vm.isLoading
                ? _c(
                    "div",
                    [
                      _c("validation-observer", {
                        ref: "observer",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ handleSubmit, invalid }) {
                                return [
                                  _c(
                                    "b-form",
                                    {
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-row", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-group col-md-6",
                                          },
                                          [
                                            _c("base-select", {
                                              attrs: {
                                                vid: "status_correspondence",
                                                name: _vm.beForm[_vm.rep]
                                                  .status_correspondence.label,
                                                label:
                                                  _vm.beForm[_vm.rep]
                                                    .status_correspondence
                                                    .label,
                                                options: _vm.beForm[
                                                  _vm.rep
                                                ].status_correspondence.options.filter(
                                                  (option) =>
                                                    _vm.status_correspondence_allowed.includes(
                                                      option.value
                                                    )
                                                ),
                                                taggable: false,
                                                multiple: false,
                                                closeOnSelect: true,
                                                rules: _vm.getRules(
                                                  "status_correspondence"
                                                ),
                                              },
                                              model: {
                                                value:
                                                  _vm.form[_vm.rep]
                                                    .status_correspondence,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form[_vm.rep],
                                                    "status_correspondence",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form[rep].status_correspondence",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-group col-md-6",
                                          },
                                          [
                                            _c("base-input", {
                                              attrs: {
                                                vid: "attribute_NAME2_value",
                                                name: _vm.beForm[_vm.rep]
                                                  .attribute_NAME2_value.label,
                                                label:
                                                  _vm.beForm[_vm.rep]
                                                    .attribute_NAME2_value
                                                    .label,
                                                placeholder:
                                                  "Inserisci il nome alternativo ",
                                                rules: _vm.getRules(
                                                  "attribute_NAME2_value"
                                                ),
                                              },
                                              model: {
                                                value:
                                                  _vm.form[_vm.rep]
                                                    .attribute_NAME2_value,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form[_vm.rep],
                                                    "attribute_NAME2_value",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form[rep].attribute_NAME2_value",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-group col-md-4",
                                          },
                                          [
                                            _c("base-select", {
                                              attrs: {
                                                vid: "country",
                                                name: "country",
                                                label: "Nazione",
                                                options: _vm.optCountries,
                                                taggable: false,
                                                multiple: false,
                                                closeOnSelect: true,
                                              },
                                              on: {
                                                select: _vm.onSelectCountry,
                                              },
                                              model: {
                                                value:
                                                  _vm.form[_vm.rep]
                                                    .attribute_NOR_value,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form[_vm.rep],
                                                    "attribute_NOR_value",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form[rep].attribute_NOR_value",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        !_vm.isForeign
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-group col-md-4",
                                              },
                                              [
                                                _c("base-select", {
                                                  attrs: {
                                                    name: "Provincia",
                                                    vid: "province",
                                                    label: "Provincia",
                                                    options: _vm.optProvinces,
                                                    taggable: false,
                                                    multiple: false,
                                                    closeOnSelect: true,
                                                  },
                                                  on: {
                                                    select:
                                                      _vm.onSelectProvince,
                                                  },
                                                  model: {
                                                    value: _vm.form.province,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "province",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.province",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.isForeign
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-group col-md-4",
                                              },
                                              [
                                                _c("base-select", {
                                                  attrs: {
                                                    vid: "attribute_CITY_value",
                                                    name: _vm.beForm[_vm.rep]
                                                      .attribute_CITY_value
                                                      .label,
                                                    label:
                                                      _vm.beForm[_vm.rep]
                                                        .attribute_CITY_value
                                                        .label,
                                                    options: _vm.optCouncils,
                                                    taggable: false,
                                                    multiple: false,
                                                    closeOnSelect: true,
                                                    rules: _vm.getRules(
                                                      "attribute_CITY_value"
                                                    ),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form[_vm.rep]
                                                        .attribute_CITY_value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form[_vm.rep],
                                                        "attribute_CITY_value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form[rep].attribute_CITY_value",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-group col-md-4",
                                              },
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    vid: "attribute_CITY2_value",
                                                    name: _vm.beForm[_vm.rep]
                                                      .attribute_CITY2_value
                                                      .label,
                                                    label:
                                                      _vm.beForm[_vm.rep]
                                                        .attribute_CITY2_value
                                                        .label,
                                                    placeholder:
                                                      "Inserisci il comune estero ",
                                                    rules: _vm.getRules(
                                                      "attribute_CITY2_value"
                                                    ),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form[_vm.rep]
                                                        .attribute_CITY2_value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form[_vm.rep],
                                                        "attribute_CITY2_value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form[rep].attribute_CITY2_value",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-group col-md-4",
                                          },
                                          [
                                            _c("base-input", {
                                              attrs: {
                                                vid: "attribute_ZIPC_value",
                                                name: _vm.beForm[_vm.rep]
                                                  .attribute_ZIPC_value.label,
                                                label:
                                                  _vm.beForm[_vm.rep]
                                                    .attribute_ZIPC_value.label,
                                                placeholder: "Inserisci il CAP",
                                                rules: _vm.getRules(
                                                  "attribute_ZIPC_value"
                                                ),
                                              },
                                              model: {
                                                value:
                                                  _vm.form[_vm.rep]
                                                    .attribute_ZIPC_value,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form[_vm.rep],
                                                    "attribute_ZIPC_value",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form[rep].attribute_ZIPC_value",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-group col-md-4",
                                          },
                                          [
                                            _c("base-input", {
                                              attrs: {
                                                vid: "attribute_ADDR_value",
                                                name: _vm.beForm[_vm.rep]
                                                  .attribute_ADDR_value.label,
                                                label:
                                                  _vm.beForm[_vm.rep]
                                                    .attribute_ADDR_value.label,
                                                placeholder:
                                                  "Inserisci un indirizzo",
                                                rules: { required: true },
                                              },
                                              model: {
                                                value:
                                                  _vm.form[_vm.rep]
                                                    .attribute_ADDR_value,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form[_vm.rep],
                                                    "attribute_ADDR_value",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form[rep].attribute_ADDR_value",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mt-2 float-right",
                                          attrs: {
                                            type: "button",
                                            disabled: invalid,
                                            variant: "outline-lisaweb",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return handleSubmit(
                                                _vm.saveCorrespondence
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              Salva\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2435193033
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }