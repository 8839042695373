var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mb-1" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "right" } },
                [
                  _vm.canVerb(_vm.resource, "store")
                    ? _c(
                        "b-button",
                        {
                          staticClass: "btn-create",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: "Crea Indirizzo",
                          },
                          on: { click: _vm.onAddCorrespondence },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "plus-circle",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v("\n          Crea"),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("table-correspondences", {
        ref: _vm.tableRef,
        attrs: {
          fields: _vm.fields,
          repository: _vm.repository,
          resource: _vm.resource,
          filterName: _vm.filterName,
          noInnerWidth: "",
          onlyActions: ["edit", "destroy"],
        },
        on: { destroy: _vm.onDelete, create: _vm.onCreate, edit: _vm.onEdit },
      }),
      _vm._v(" "),
      _c("add-correspondence-modal", {
        attrs: { status_correspondence_allowed: _vm.correspondencesAllowed },
        on: { create: _vm.onCreate },
      }),
      _vm._v(" "),
      _c("edit-correspondence-modal", {
        ref: _vm.editModalRef,
        attrs: { status_correspondence_allowed: _vm.correspondencesAllowed },
        on: { update: _vm.onUpdate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }